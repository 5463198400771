<template>
    <AppModal
        :show="isOpen"
        :description="context.description"
        class="max-w-3xl"
        @close="close"
        @closed="onClosed">
        <div class="flex items-center justify-between">
            <h1 class="text-xl font-bold text-iel-blue">
                {{ context.title }}
            </h1>
        </div>
        <form @submit.prevent="enable">
            <!-- Carrier -->
            <AppHeading
                size="md"
                class="text-iel-gray">
                {{ $t('Carrier') }}
            </AppHeading>
            <div class="mb-4 grid gap-2">
                <div class="flex gap-2 md:grid-cols-3">
                    <AppInput
                        v-model="form.minimum_temperature"
                        name="minimum_temperature"
                        label="Minimum Temperature"
                        type="number"
                        :required="tempRequired()" />
                    <AppInput
                        v-model="form.reefer_set_temperature"
                        name="reefer_set_temperature"
                        label="Reefer Set Temperature"
                        type="number"
                        :required="tempRequired()" />
                    <AppInput
                        v-model="form.maximum_temperature"
                        name="maximum_temperature"
                        label="Maximum Temperature"
                        type="number"
                        :required="tempRequired()" />
                    <AppSelect
                        v-model="form.temperature_units"
                        name="temperature_units"
                        :options="{ c: 'C', f: 'F' }"
                        :required="tempRequired()" />
                </div>
            </div>

            <!-- Timing -->
            <AppHeading
                size="md"
                class="text-iel-gray">
                {{ $t('Timing') }}
            </AppHeading>
            <div class="grid gap-2 md:grid-cols-3">
                <AppDateTime
                    v-model:datetime="form.starts_at"
                    v-model:timezone="form.starts_at_timezone"
                    :field-names="{ datetime: 'starts_at', timezone: 'starts_at_timezone' }"
                    name="starts_at"
                    label="Start Date/Time"
                    :disabled="
                        context.load.customer.tracking_service === 'freight_verify' ||
                        context.load.customer.tracking_service === 'project44'
                    "
                    required />
                <AppDateTime
                    v-model:datetime="form.ends_at"
                    v-model:timezone="form.ends_at_timezone"
                    :field-names="{ datetime: 'ends_at', timezone: 'ends_at_timezone' }"
                    name="ends_at"
                    label="End Date/Time"
                    :disabled="
                        context.load.customer.tracking_service === 'freight_verify' ||
                        context.load.customer.tracking_service === 'project44'
                    "
                    required />
                <AppInput
                    v-model="form.check_in_email"
                    name="check_in_email"
                    label="Check-In Email" />
                <AppTextarea
                    v-model="form.email_notes"
                    name="email_notes"
                    label="Email Notes"
                    class="col-span-2" />
            </div>

            <div class="flex justify-end gap-2 pt-2">
                <AppButton
                    variant="outline"
                    @click="
                        () => {
                            close();
                        }
                    ">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton
                    v-if="context.mode === 'create'"
                    type="submit"
                    :disabled="form.processing">
                    {{ $t('Enable') }}
                </AppButton>
                <AppButton
                    v-if="context.mode === 'edit'"
                    type="submit"
                    :disabled="form.processing">
                    {{ $t('Update') }}
                </AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('LoadMacropointModal');

const form = useForm({
    minimum_temperature: null,
    reefer_set_temperature: null,
    maximum_temperature: null,
    temperature_units: null,
    starts_at: '',
    starts_at_timezone: 'America/New_York',
    ends_at: '',
    ends_at_timezone: 'America/New_York',
    check_in_email: '',
    email_notes: ''
});

function tempRequired() {
    const requiredEquipmentTypes = ['Reefer', 'Reefer Team', 'Ref-Haz', 'Refrigerated Box Truck'];
    return requiredEquipmentTypes.includes(context.value.load.required_equipment_type);
}

provide('form', form);

watch(isOpen, value => {
    form.reset();
    form.clearErrors();

    form.starts_at = context.value.load.mpInitialStart;
    form.starts_at_timezone = context.value.load.mpInitialStartTz;
    form.ends_at = context.value.load.mpInitialEnd;
    form.ends_at_timezone = context.value.load.mpInitialEndTz;

    if (tempRequired()) {
        form.reefer_set_temperature = context.value.load.temperature;
        form.temperature_units = context.value.load.temperature_units;
    }

    if (context.value.mode === 'edit') {
        form.minimum_temperature = context.value.load.macropointOrder.minimum_temperature;
        form.reefer_set_temperature = context.value.load.macropointOrder.reefer_set_temperature;
        form.maximum_temperature = context.value.load.macropointOrder.maximum_temperature;
        form.temperature_units = context.value.load.macropointOrder.temperature_units;
        form.starts_at = context.value.load.macropointOrder.starts_at;
        form.starts_at_timezone = context.value.load.macropointOrder.starts_at_timezone;
        form.ends_at = context.value.load.macropointOrder.ends_at;
        form.ends_at_timezone = context.value.load.macropointOrder.ends_at_timezone;
        form.check_in_email = context.value.load.macropointOrder.check_in_email;
        form.email_notes = context.value.load.macropointOrder.email_notes;
    }

    if (!value) return;
});

async function enable() {
    if (context.value.mode === 'create') {
        form.post(route('loads.macropoint.store', [context.value.load]), {
            preserveScroll: true,
            only: ['load'],
            onSuccess() {
                close();
            }
        });
    }

    if (context.value.mode === 'edit') {
        form.patch(route('loads.macropoint.update', [context.value.load, context.value.macropointOrder]), {
            preserveScroll: true,
            only: ['load'],
            onSuccess() {
                close();
            }
        });
    }
}
</script>
