<template>
    <div
        class="flex items-center gap-1"
        :disabled="taskDisabled">
        <button
            :id="`load-checkbox-button-${load.id}-${task.name.replaceAll('_', '-')}`"
            class="flex items-center gap-2 whitespace-nowrap"
            :class="!taskDisabled ? 'border-iel-gray' : 'cursor-not-allowed !text-iel-gray'"
            type="button"
            :disabled="taskDisabled"
            @click="$emit('completeTask')">
            <input
                :id="$attrs.id + '-input'"
                :checked="checked"
                class="h-4 w-4 rounded text-iel-blue"
                :class="[
                    !taskDisabled ? 'border-iel-gray' : 'cursor-not-allowed !text-iel-gray',
                    $attrs.disabled || unauthorized ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
                ]"
                type="checkbox"
                :disabled="taskDisabled || $attrs.disabled || unauthorized"
                :aria-label="`checkbox for ${startCase(task.type)}`" />
            <div
                class="text-sm font-medium"
                :class="[
                    taskDisabled ? 'cursor-not-allowed text-iel-gray' : 'text-iel-blue',
                    $attrs.disabled || unauthorized ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
                ]"
                :disabled="taskDisabled || $attrs.disabled || unauthorized">
                <slot />
            </div>
        </button>
        <div
            v-if="task && (task.past_due || task.due_soon)"
            :id="`load-${task.name.replaceAll('_', '-')}-overdue-section`"
            class="relative flex items-center space-x-1">
            <AppButton
                :id="`load-${task.name.replaceAll('_', '-')}-snooze-button`"
                size="sm"
                variant="invisible"
                :disabled="taskDisabled"
                @click="snoozeTask(load, task)">
                <AppIcon name="fas fa-alarm-snooze" />
            </AppButton>
            <AppWarningIcon
                :id="`load-${task.name.replaceAll('_', '-')}-overdue-icon`"
                class="tooltip"
                size="sm"
                :disabled="taskDisabled">
                <span class="tooltiptext">
                    <p v-if="task.past_due">Past Due</p>
                    <p v-else>Due Soon</p>
                </span>
            </AppWarningIcon>
        </div>
    </div>
</template>

<script setup>
import { startCase } from 'lodash-es';

defineEmits(['completeTask']);

const props = defineProps({
    checked: Boolean,
    load: Object,
    task: Object,
    permission: String
});

const tasks = ['collect_paperwork', 'ready_to_be_invoiced', 'invoice_customer', 'pay_carrier', 'collect_payment'];

const isTaskDisabled = (load, task) => {
    if (load.is_canceled) return true;
    if (load.is_tonu) return isTonuTaskDisabled(load, task);
    return false;
};

const isTonuTaskDisabled = (load, task) => {
    const { total_pay_carrier_amount, total_customer_billing_amount } = load;

    if (total_pay_carrier_amount > 0 && total_customer_billing_amount > 0) {
        return !tasks.includes(task.name);
    }

    if (total_pay_carrier_amount > 0) {
        return task.name !== 'pay_carrier';
    }

    if (total_customer_billing_amount > 0) {
        return !tasks.filter(taskName => taskName !== 'pay_carrier').includes(task.name);
    }

    return false;
};

const taskDisabled = computed(() => isTaskDisabled(props.load, props.task));

const { open: openSnoozeModal } = useModal('SnoozeModal');

function snoozeTask(load, task) {
    openSnoozeModal(
        {
            task: {
                load,
                task
            }
        },
        close()
    );
}

const { can } = useAuth();
const unauthorized = computed(() => props.permission && !can(props.permission));
</script>
