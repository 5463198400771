<template>
    <div class="m-3 flex flex-row items-center gap-2 text-sm text-iel-blue lg:gap-6">
        <!-- Load Creator doesn't have a Phone # ATM -->
        <AppPhoneLink
            v-if="load.created_by"
            :tooltip-text="true"
            :toggle="false"
            :number="load.dispatcher?.phone_number"
            :name="load.created_by?.name"
            title="Load Contact">
            <template #text>
                <div class="pl-1">
                    <div class="flex gap-2">
                        <p class="font-bold">Load Owner:</p>
                        <p>{{ load.created_by?.name }}</p>
                    </div>
                    <div class="flex font-bold">Manager: -</div>
                    <div class="flex font-bold">Location: -</div>
                    <div class="flex gap-2">
                        <p class="font-bold">Phone:</p>
                        <p>{{ tooltipPhone() }}</p>
                    </div>
                    <div class="flex gap-2">
                        <p class="font-bold">Email:</p>
                        <p>{{ tooltipEmail() }}</p>
                    </div>
                </div>
            </template>
        </AppPhoneLink>
        <span
            v-if="load.stops.length != 0"
            class="h-6 border-l-2 border-iel-blue px-2"></span>
        <LoadCardProgressBar
            v-if="load.stops.length != 0"
            :load="load"
            class="w-1/4" />
        <div
            v-if="load.trackings != 0"
            class="flex flex-row font-bold">
            Current Location:
            <p class="pl-1 font-normal">{{ currentLocation }}</p>
        </div>
        <div
            v-if="load.trackings != 0"
            class="group flex gap-1 font-bold">
            ETA
            <div class="flex flex-row">
                <AppIcon
                    name="far fa-location-dot"
                    class="self-center pl-1" />
                <p class="pl-1 font-normal">{{ milesToNextStop }} Miles</p>
            </div>
            <div class="flex flex-row pl-1">
                <AppIcon
                    name="far fa-clock"
                    class="self-center pl-1" />
                <p class="pl-1 font-normal">{{ formatETA(load?.latest_eta) }}</p>
            </div>
        </div>
        <div
            v-if="load.trackings != 0"
            class="flex flex-row">
            <p class="font-bold">Last Updated:</p>
            <p class="pl-1 font-normal">
                {{ formatETA(lastUpdate) }}
            </p>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    load: Object
});

const currentLocation = computed(() => {
    return props.load.trackings.length === 0 ? '-' : props.load.trackings.at(0).location;
});

const milesToNextStop = computed(() => {
    return props.load.trackings.length === 0 ? '-' : props.load.trackings.at(0).miles_to_next_stop;
});

const lastUpdate = computed(() => {
    return props.load.trackings.length === 0 ? '-' : props.load.trackings.at(0).event_happened_at;
});

function tooltipPhone() {
    return props.load.customer?.team_contact_phone ? props.load.customer?.team_contact_phone : '-';
}

function tooltipEmail() {
    return props.load.customer?.team_contact_email ? props.load.customer?.team_contact_email : '-';
}

function formatETA(date) {
    const eta = new Date(date);

    const options = {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: props.load.eta_timezone,
        timeZoneName: 'short'
    };

    const formatter = new Intl.DateTimeFormat(navigator.language, options);

    return !date ? null : formatter.format(eta);
}
</script>
