<template>
    <AppTablePill
        v-if="comchecks.length > 0"
        :id="`load-${page.props.load.id}_advances-table`"
        :columns="[
            { name: 'formatted_sent_to', label: 'Sent To' },
            { name: 'name', label: 'Name' },
            { name: 'contact_info', label: 'Contact Info' },
            { name: 'reason', label: 'Reason' },
            { name: 'amount', label: 'Amount' },
            { name: 'fee', label: 'Fee' },
            { name: 'icon-receipt-required', label: 'Receipt Req\'d' },
            { name: 'icon-charge-carrier', label: 'Charge Carrier' },
            { name: 'express_check_number', label: 'Check #' },
            { name: 'status', label: 'Status' },
            { name: 'actions', label: '' }
        ]"
        :data="comchecks"
        :parent-id="page.props.load.id">
        <template #name-cell="comcheck">
            <span>{{ comcheck.row.first_name }} {{ comcheck.row.last_name }}</span>
        </template>
        <template #amount-cell="comcheck">
            <span>${{ comcheck.row.amount }}</span>
        </template>
        <template #icon-receipt-required-cell="comcheck">
            <div class="flex justify-center">
                <AppIcon
                    :id="`load-${page.props.load.id}-receipt-required-icon`"
                    :name="comcheck.row.receipt_required ? 'far fa-circle-check' : 'fal fa-circle-xmark'"
                    :class="comcheck.row.receipt_required ? 'text-iel-green' : 'text-iel-red'"
                    class="h-5 w-5"
                    solid />
            </div>
        </template>

        <template #icon-charge-carrier-cell="comcheck">
            <div class="flex justify-center">
                <AppIcon
                    :id="`load-${page.props.load.id}-charge-carrier-icon`"
                    :name="comcheck.row.charge_carrier ? 'far fa-circle-check' : 'fal fa-circle-xmark'"
                    :class="comcheck.row.charge_carrier ? 'text-iel-green' : 'text-iel-red'"
                    class="h-5 w-5"
                    solid />
            </div>
        </template>

        <template #actions-cell="comcheck">
            <div class="flex w-full justify-end gap-4">
                <AppButton
                    :id="`load-card-${page.props.load.id}-actions-void-comcheck-button`"
                    type="button"
                    permission="update_advances"
                    :disabled="loadDisabled"
                    @click="voidComcheck(comcheck.row.id)">
                    {{ $t('Void') }}
                </AppButton>
                <AppButton
                    :id="`load-card-${page.props.load.id}-actions-check-advance-status-comcheck-button`"
                    type="button"
                    permission="update_advances"
                    :disabled="loadDisabled"
                    @click="checkStatus(comcheck.row)">
                    {{ $t('Check Advance Status') }}
                </AppButton>
                <AppButton
                    :id="`load-card-${page.props.load.id}-actions-edit-comcheck-button`"
                    type="button"
                    permission="update_advances"
                    :disabled="loadDisabled"
                    @click="editComcheck(comcheck.row)">
                    {{ $t('Edit') }}
                </AppButton>
                <AppButton
                    :id="`load-card-${page.props.load.id}-actions-transfer-comcheck-button`"
                    type="button"
                    permission="update_advances"
                    :disabled="loadDisabled"
                    @click="transferComcheck(comcheck.row.id)">
                    {{ $t('Transfer') }}
                </AppButton>
            </div>
        </template>
    </AppTablePill>
</template>

<script setup>
import { usePage, router } from '@inertiajs/vue3';
import { useLoadDisabled } from '@/helpers';

const page = usePage();

const { open: openLoadComcheckModal } = useModal('LoadComcheckModal');
const { open: openTransferComcheckModal } = useModal('TransferComcheckModal');

function voidComcheck(advanceId) {
    if (confirm('Are you sure you want to void this Comcheck?')) {
        router.delete(route('comcheck.cancel', { advance: advanceId }), {
            preserveScroll: true,
            onError(e) {
                alert(e.error_message);
            }
        });
    }
}

const editComcheck = advance => {
    openLoadComcheckModal({
        title: 'Edit Comcheck',
        mode: 'edit',
        load: page.props.load,
        advance: advance
    });
};

function transferComcheck(advance) {
    openTransferComcheckModal({
        title: 'Transfer Comcheck',
        advance: advance
    });
}

const formatSendTo = value => {
    const mappings = {
        third_party: 'Third Party',
        driver: 'Driver',
        dispatcher: 'Dispatcher'
    };
    return mappings[value] || value;
};

const comchecks = computed(() =>
    page.props.load.advances.map(advance => ({
        formatted_sent_to: formatSendTo(advance.sent_to),
        sent_to: advance.sent_to,
        id: advance.id,
        carrier_id: advance.carrier_id,
        load_id: advance.load_id,
        first_name: advance.first_name,
        last_name: advance.last_name,
        contact_info: advance.contact_info,
        sent_via: advance.sent_via,
        reason: advance.reason,
        amount: advance.amount,
        fee: advance.fee,
        receipt_required: advance.receipt_required,
        charge_carrier: advance.charge_carrier,
        express_check_number: advance.express_check_number,
        waive_fee: advance.waive_fee,
        status: advance.status
    }))
);

const loadDisabled = useLoadDisabled(page.props.load);

async function checkStatus(advance) {
    router.visit(route('comcheck.inquiry', { advance: advance.id }, {}), {
        preserveScroll: true,
        onError(e) {
            alert(e.error_message);
        }
    });
}
</script>
